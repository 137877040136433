import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'

import { switchTheme } from './components/switch'

export const venvApplicationTheme = extendTheme({
  ...theme,
  colors: {
    gray: {
      '50': '#F8F8F8',
      '100': '#F2F2F2',
    },
  },
  components: {
    Button: {
      variants: {
        link: {
          color: 'gray.900',
        },
        primary: {
          color: 'white',
          bg: 'gray.700',
        },
        secondary: {
          color: 'gray.700',
          bg: 'gray.200',
        },
      },
    },
    Switch: {
      ...switchTheme,
    },
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50',
      },
    },
  },
})

/*
Accent = gray.700
Secondary = gray.300
Inactive = gray.100
Background = gray.50
*/

export const venvMarketingTheme = extendTheme({
  ...theme,
  colors: {
    gray: {
      '50': '#F8F8F8',
      '100': '#F2F2F2',
    },
  },
  components: {
    Button: {
      variants: {
        link: {
          color: 'gray.900',
        },
        primary: {
          color: 'white',
          bg: 'gray.700',
        },
        secondary: {
          color: 'gray.700',
          bg: 'gray.200',
        },
      },
    },
    Switch: {
      ...switchTheme,
    },
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50',
      },
    },
  },
})
