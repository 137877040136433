import * as React from 'react'

import { PosterProps } from '../app/@types/poster'
import {
  Footer,
  HeroWithVideo,
  NavBar,
  Statistics,
} from '../components/Posters'

export const EmailPoster = (props: PosterProps) => {
  return (
    <>
      <NavBar {...props} />
      <HeroWithVideo {...props} />
      <Statistics {...props} />
      <Footer {...props} />
    </>
  )
}
