import * as React from 'react'

import { Box, Flex, Icon, Spacer } from '@chakra-ui/react'
import { HTMLChakraProps, chakra } from '@chakra-ui/react'
import { IconProps } from '@chakra-ui/react'

export const VenvIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 512 512" {...props}>
      <path
        fill="#5089c6"
        fillRule="evenodd"
        stroke="none"
        d="M 46.949501 10.58252 L 109.949028 10.816101 L 287.686584 501.35611 L 226.123764 503.250305 Z"
      />
      <path
        fill="#5089c6"
        fillRule="evenodd"
        stroke="none"
        d="M 133.34494 10.238098 L 172 11 L 316.736816 409.498444 L 277.364471 407.588623 Z"
      />
      <path
        fill="#035397"
        fillRule="evenodd"
        stroke="none"
        d="M 401.067627 11.233398 L 465.067139 11.470764 L 285.955261 502.718292 L 227 503 Z"
      />
    </Icon>
  )
}

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <Flex alignItems="center" direction="column">
    <Spacer />
    <Box height={16}>
      <chakra.svg
        color="accent"
        width="auto"
        viewBox="0 0 256 256"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g id="pairspaces-logo">
          <circle cx="128" cy="128" r="128" fill="#2E2E2E" />
          <path
            d="M107.348 161.252C91.9261 176.428 82.0984 197.717 99.0334 215.589C104.551 221.429 112.06 225.609 120.106 227.445C129.647 229.632 138.363 227.991 145.547 223.948C151.945 220.355 157.175 214.866 160.777 208.401C164.398 201.917 166.352 194.534 166.141 187.209C165.911 179.162 162.999 171.115 156.735 164.357C147.865 154.806 134.608 146.466 121.677 138.322C107.539 129.415 93.7651 120.743 84.4548 110.099C77.8072 102.501 73.516 94.3755 71.1597 86.1332C67.788 74.297 68.5351 62.4609 72.2133 51.6989C75.8149 41.1128 82.2134 31.6204 90.2594 24.3156C97.0793 18.1045 105.202 13.3779 113.88 10.7997C126.524 7.04963 141.677 9.31529 154.934 16.1514C164.302 20.9952 172.903 28.1829 179.11 37.2065C185.49 46.484 189.34 57.6561 188.976 70.1563C188.555 84.4535 182.654 100.235 168.67 116.505L153.497 102.969C164.091 90.645 168.536 79.3752 168.823 69.5899C169.053 61.8163 166.62 54.824 162.597 48.9645C158.401 42.8511 152.444 37.9096 145.853 34.5111C136.926 29.9016 127.175 28.261 119.512 30.5266C113.842 32.2064 108.42 35.4096 103.708 39.687C98.1138 44.7652 93.7077 51.2497 91.2747 58.3592C88.9184 65.2929 88.4011 72.8908 90.5467 80.3909C92.0602 85.723 94.972 91.1333 99.5698 96.3873C107.041 104.923 119.493 112.774 132.29 120.821C146.371 129.689 160.796 138.771 171.371 150.177C181.313 160.881 185.93 173.752 186.313 186.643C186.639 197.796 183.746 208.909 178.344 218.558C172.942 228.245 165.011 236.527 155.279 241.996C144.034 248.324 130.451 250.882 115.681 247.504C103.842 244.789 92.7498 238.558 84.4931 229.827C78.9375 223.948 74.6271 216.937 72.1942 209.065C69.7421 201.135 69.2248 192.424 71.2555 183.205C73.88 171.33 80.7574 158.771 93.3628 146.388L107.348 161.252Z"
            fill="#F2F2F2"
          />
        </g>
      </chakra.svg>
    </Box>
    <Spacer />
  </Flex>
)
