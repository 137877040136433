import { create } from 'zustand'

import { ApplicationError } from '@app/@types/application'

import { FeatureService } from '../services'
import { useAccountStore } from './'

const initialState = {
  error: undefined,
  flags: {},
  redirectPath: undefined,
  willShowBanner: false,
  willShowGuide: false,
  willShowOAuth: false,
}

export type ApplicationStore = {
  error?: ApplicationError
  featureFlagFor: (name: string) => boolean
  featureFlags: () => void
  flags: { [key: string]: { enabled: boolean } }
  getRedirectPath: (value?: string) => void
  isCurrentMenuItem: (label: string) => 'page' | false
  redirectPath?: string
  setError: (error?: ApplicationError) => void
  willShowBanner: boolean
  willShowGuide: boolean
  willShowOAuth: boolean
}

export const useApplicationStore = create<ApplicationStore>((set, get) => ({
  ...initialState,
  featureFlagFor: (name: string) => {
    try {
      return get().flags[name].enabled
    } catch {
      return false
    }
  },
  featureFlags: async () => {
    const response = await FeatureService.featureFlags()
    if (response.ok) {
      const data = await response.json()
      set({
        ...initialState,
        flags: (
          data as {
            flags: { [key: string]: { enabled: boolean } }
          }
        ).flags,
        willShowOAuth: data.flags.willShowOAuth,
      })
    }
  },
  getRedirectPath: (value?: string) => {
    const role = useAccountStore.getState().role

    if (['account.read', 'billing.read'].includes(role)) {
      set({ redirectPath: '/accounts/billing' })
      return
    }

    set({ redirectPath: value })
  },
  isCurrentMenuItem: (label: string) => {
    return label == window.location.pathname ? 'page' : false
  },
  setError: (error?: ApplicationError) => {
    console.log(`setError ${error}`)
    set({ error })
  },
}))
