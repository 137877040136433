import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'
import { SlidingCalculator } from '../SlidingCalculator'

export const Statistics = (props: PosterProps) => {
  const { t } = useTranslation()

  return (
    <Box as="section">
      <Container py={{ base: '12', md: '16' }} maxW="5xl">
        <Stack spacing={{ base: '4', md: '8' }} textAlign="center">
          <Stack spacing={{ base: '4', md: '5' }}>
            <Heading fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}>
              {t(`posters.${props.viewModel.name}.proof.heading`)}
            </Heading>
            <Text fontSize={{ base: 'lg', md: '3xl' }} lineHeight="1.25">
              {t(`posters.${props.viewModel.name}.proof.subHeading`)}
            </Text>
          </Stack>
          <SlidingCalculator />
          <Center>
            <Stack
              direction={{ base: 'column-reverse', md: 'row' }}
              spacing="3"
            >
              {t(
                `posters.${props.viewModel.name}.calculator.header.0.actions.secondary`
              ) && (
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/pages/productivity"
                  variant="secondary"
                >
                  {t(
                    `posters.${props.viewModel.name}.calculator.header.0.actions.secondary`
                  )}
                </Button>
              )}
              {t(
                `posters.${props.viewModel.name}.calculator.header.0.actions.primary`
              ) && (
                <Button
                  as={Link}
                  fontSize="1.125rem"
                  to="/auth/signup"
                  variant="primary"
                >
                  {t(
                    `posters.${props.viewModel.name}.calculator.header.0.actions.primary`
                  )}
                </Button>
              )}
            </Stack>
          </Center>
        </Stack>
      </Container>
    </Box>
  )
}
