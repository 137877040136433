import { ApplicationConstants } from '@app/constants'

import { ApplicationError } from '../@types/application'
import { AnalyticsService } from '../services/analyticsService'
import { AccountStore } from '../stores/accountStore'
import { ApplicationStore } from '../stores/applicationStore'
import { AuthStore } from '../stores/authStore'

export class AuthViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  authStore: AuthStore
  _isBitbucket = false
  _isGitHub = false
  _isGitLab = false
  _isUpgrading = false

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    authStore: AuthStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.authStore = authStore
  }

  get isBitbucket() {
    return this._isBitbucket
  }

  set isBitbucket(value: boolean) {
    this._isBitbucket = value

    this._isGitHub = false
    this._isGitLab = false
  }

  get isGitHub() {
    return this._isGitHub
  }

  set isGitHub(value: boolean) {
    this._isGitHub = value

    this._isBitbucket = false
    this._isGitLab = false
  }

  get isGitLab() {
    return this._isGitLab
  }

  set isGitLab(value: boolean) {
    this._isGitLab = value

    this._isBitbucket = false
    this._isGitHub = false
  }

  get isUpgrading() {
    return this._isUpgrading
  }

  set isUpgrading(value: boolean) {
    this._isUpgrading = value
  }

  async confirmSignIn(code: string) {
    const okerr = await this.authStore.confirmSignIn(code)

    if (!okerr?.ok) {
      this.applicationStore.setError(okerr?.error)
    }
  }

  async confirmGuest() {
    this.authStore.setIsLoading(false)
    this.isUpgrading = true
  }

  async signIn(email: string) {
    if (this.isUpgrading) {
      return await this.signUp(email)
    }

    const okerr = await this.authStore.signIn(email)

    if (!okerr?.ok) {
      if (okerr?.error?.action == ApplicationConstants.PROMPT_USER) {
        this.confirmGuest()
      } else {
        this.applicationStore.setError(okerr?.error)
      }
    }
  }

  async signInBitbucket() {
    this.isBitbucket = true
    this.authStore.setIsLoading(false)
    setTimeout(() => {
      return this.authStore
        .signUpBitbucket()
        .catch((error: ApplicationError) => {
          // this.error = error
          console.log(error)
          this.authStore.setIsLoading(false)
        })
    }, 500)
  }

  async signInGitHub() {
    this.isGitHub = true
    this.authStore.setIsLoading(true)
    setTimeout(() => {
      return this.authStore.signUpGitHub().catch((error: ApplicationError) => {
        // this.error = error
        console.log(error)
        this.authStore.setIsLoading(false)
      })
    }, 500)
  }

  async signInGitLab() {
    this.isGitLab = true
    this.authStore.setIsLoading(true)
    setTimeout(() => {
      return this.authStore.signUpGitLab().catch((error: ApplicationError) => {
        // this.error = error
        console.log(error)
        this.authStore.setIsLoading(false)
      })
    }, 500)
  }

  async signUp(email: string) {
    const okerr = await this.authStore.signUp(email, {
      clientMetadata: {
        isUpgrading: this.authStore.isUpgrading.toString(),
      },
    })

    if (!okerr?.ok) {
      if (okerr?.error?.action == ApplicationConstants.PROMPT_USER) {
        this.confirmGuest()
      } else {
        this.applicationStore.setError(okerr?.error)
      }
    }
  }

  async signUpBitbucket() {
    this.isBitbucket = true
    this.authStore.setIsLoading(true)
    return this.authStore
      .signUpBitbucket()
      .then(() => {
        this.authStore.setIsLoading(true)

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        // this.error = error
        console.log(error)
        this.authStore.setIsLoading(false)
      })
  }

  async signUpGitHub() {
    this.isGitHub = true
    this.authStore.setIsLoading(true)
    return this.authStore
      .signUpGitHub()
      .then(() => {
        this.authStore.setIsLoading(false)

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        // this.error = error
        console.log(error)
        this.authStore.setIsLoading(false)
      })
  }

  async signUpGitLab() {
    this.isGitLab = true
    this.authStore.setIsLoading(true)
    return this.authStore
      .signUpGitLab()
      .then(() => {
        this.authStore.setIsLoading(false)

        AnalyticsService.didSignUp()
      })
      .catch((error: ApplicationError) => {
        // this.error = error
        console.log(error)
        this.authStore.setIsLoading(false)
      })
  }
}
