import { v4 as uuidv4 } from 'uuid'

import { ApplicationConstants as Constants } from './../constants'

type IFeatureService = {
  featureFlag: (name: string) => Promise<Response>
  featureFlags: () => Promise<Response>
}

class FeatureServiceClass implements IFeatureService {
  async featureFlag(name: string): Promise<Response> {
    return fetch(`${Constants.API_URI}/flags/${name}`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }

  async featureFlags(): Promise<Response> {
    return fetch(`${Constants.API_URI}/flags`, {
      method: Constants.HTTP_METHODS.GET,
      headers: {
        [Constants.HEADERS.CONTENT_TYPE]: 'application/json',
        [Constants.HEADERS.CORRELATION_ID]: uuidv4(),
        [Constants.HEADERS.X_VENV_API_VERSION]: Constants.API_HEADER,
      },
    })
  }
}

export const FeatureService = new FeatureServiceClass()
