import * as React from 'react'

import { Box, BoxProps, Text } from '@chakra-ui/react'

type Props = {
  imageSrc?: string
  jobTitle: string
  name: string
} & BoxProps

export const Quotee = (props: Props) => {
  const { name, jobTitle, ...boxProps } = props
  return (
    <Box {...boxProps}>
      {/* <Img
        src={imageSrc}
        mx="auto"
        objectFit="cover"
        w="16"
        h="16"
        rounded="full"
      /> */}
      <Box mt="3">
        <Text as="cite" fontStyle="normal" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {jobTitle}
        </Text>
      </Box>
    </Box>
  )
}
