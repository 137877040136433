import { SpaceViewModel } from '../viewModels/spaceViewModel'

export enum InstanceType {
  T2_MICRO = 't2.micro',
  T3_MEDIUM = 't3.medium',
}

export enum OperatingSystem {
  AMZN_LINUX_2023 = 'amzn_linux_2023',
  UBUNTU_22_04_LTS = 'ubuntu_22_04_lts',
}

export enum ProviderType {
  AWS = 'AWS',
  AZURE = 'Azure',
  GOOGLE_CLOUD = 'Google',
}

export enum AWSRegion {
  AP_SOUTHEAST_1 = 'ap-southeast-1',
  EU_CENTRAL_1 = 'eu-central-1',
  EU_NORTH_1 = 'eu-north-1',
  EU_WEST_1 = 'eu-west-1',
  US_EAST_1 = 'us-east-1',
  US_WEST_1 = 'us-west-1',
}

export enum SpaceStatus {
  DELETED = 'deleted',
  DELETING = 'deleting',
  DEPLOYING = 'deploying',
  PENDING_USAGE = 'pending_usage',
  RUNNING = 'running',
  STOPPED = 'stopped',
  TERMINATED = 'terminated',
}

export enum SpaceType {
  AWS_EC2_CONTAINER_INSTANCE = 'ec2_container',
  AWS_EC2_INSTANCE = 'ec2',
  DEV_CONTAINER = 'devcontainer',
}

export type NewSpace = {
  action: string
  configuration: {
    operatingSystem: OperatingSystem
    resourceType: InstanceType
  }
  name: string
  type?: SpaceType
  user?: string
}

export type Space = {
  _id: string
  isShared?: boolean
  name: string
  spaceType?: SpaceType
  status?: SpaceStatus
  users: {
    collection: string
    database: string
    id: string
  }[]
}

export type SpaceUser = {
  email: string
  id: string
}

// Forms

export type NewSpaceFormInput = {
  name: string
  provider: ProviderType
  type: SpaceType
}

export type ShareSpaceFormInput = {
  members: SpaceUser[]
}

// Responses

export type ShareSpaceResponse = {
  users: {
    collection: string
    database: string
    id: string
  }[]
}

// Views

export type ModalProps = ViewProps & {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onShare?: (values: ShareSpaceFormInput) => void
  onSubmit?: (values: NewSpaceFormInput) => void
}

export type ViewProps = {
  viewModel: SpaceViewModel
}
