import { Hub } from 'aws-amplify/utils'
import { produce } from 'immer'
import { create } from 'zustand'

import { ApplicationConstants as Constants } from '@app/constants'
import { errorObjectFromError } from '@app/utilities'

import { User } from '../@types/account'
import { OkErr } from '../@types/application'
import { AuthService } from '../services'

type SetState = (state: AuthStore) => void

const initialState = {
  challengeAttempts: 2,
  didSignOut: false,
  isAuthenticated: false,
  isAuthenticating: true,
  isCognito: false,
  isLoading: false,
  isSignedOut: true,
  isUpgrading: false,
  user: undefined,
}

export type AuthStore = {
  challengeAttempts: number
  confirmSignIn: (code: string) => Promise<OkErr | undefined>
  didSignOut: boolean
  getCurrentSession: () => void
  isAuthenticated: boolean
  isAuthenticating: boolean
  isCognito: boolean
  isLoading: boolean
  isSignedOut: boolean
  isUpgrading: boolean
  listenToHub: () => () => void
  setChallengeAttempts: (value: number) => void
  setDidSignOut: (value: boolean) => void
  setIsAuthenticated: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setUser: (user?: User) => void
  signIn: (email: string) => Promise<OkErr | undefined>
  signOut: () => Promise<unknown>
  signUp: (email: string, options: object) => Promise<OkErr | undefined>
  signUpBitbucket: () => Promise<unknown>
  signUpGitHub: () => Promise<unknown>
  signUpGitLab: () => Promise<unknown>
  signUpGoogle: () => Promise<unknown>
  user?: User
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  ...initialState,
  confirmSignIn: async (code: string) => {
    const okerr: OkErr = {
      ok: false,
    }
    const setState = (func: SetState) => set(produce(get(), func))

    setState((state: AuthStore) => {
      state.isCognito = true
      state.isLoading = false
    })

    const user = get().user

    try {
      const response = AuthService.confirmSignIn(user, code)
      await response

      okerr.ok = true
    } catch (error) {
      okerr.error = {
        message: 'requests.auth.confirm.error.message',
        type: Constants.HTTP_ERROR,
      }
      get().setUser(undefined)
    }

    return new Promise(resolve => {
      resolve(okerr)
    })
  },
  getCurrentSession: () => {
    const setState = (func: SetState) => set(produce(get(), func))

    set({ isAuthenticating: true })

    const response = AuthService.currentSession()
    response
      .then(session => {
        const { tokens } = session

        if (typeof tokens !== 'undefined') {
          setState((state: AuthStore) => {
            state.isAuthenticated = true
            state.isAuthenticating = false
            state.isLoading = false
            state.didSignOut = false
          })
        } else {
          set({
            isAuthenticated: false,
            isAuthenticating: false,
            didSignOut: true,
          })
        }
      })
      .catch(() => {
        set({
          isAuthenticated: false,
          isAuthenticating: false,
          didSignOut: true,
        })
      })
  },
  identities: () => {
    return AuthService.currentSession().then(session => {
      return session
    })
  },
  listenToHub: () => {
    return Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          set({
            isAuthenticated: true,
            isAuthenticating: false,
          })
          break
        case 'tokenRefresh':
        case 'tokenRefresh_failure':
        case 'customOAuthState':
        case 'signInWithRedirect_failure':
          break
        case 'signedOut':
          set({
            isAuthenticated: false,
            isAuthenticating: false,
          })
          break
        case 'signInWithRedirect':
          set({
            isAuthenticated: true,
            isAuthenticating: false,
          })
          break
      }
    })
  },
  setChallengeAttempts: (value: number) => {
    set({ challengeAttempts: value })
  },
  setDidSignOut: (value: boolean) => {
    const setState = (func: SetState) => set(produce(get(), func))

    setState((state: AuthStore) => {
      state.didSignOut = value
    })
  },
  // Used for tests
  setIsAuthenticated: (value: boolean) => {
    set({ isAuthenticated: value })
  },
  setIsLoading: (value: boolean) => {
    set({ isLoading: value })
  },
  setUser: (user?: User) => {
    set({ user })
  },
  signIn: async (email: string) => {
    const okerr: OkErr = {
      ok: false,
    }
    const setState = (func: SetState) => set(produce(get(), func))

    setState((state: AuthStore) => {
      state.isCognito = true
    })

    try {
      const response = AuthService.signIn(email)
      await response

      setState((state: AuthStore) => {
        state.isLoading = false
        state.user = { email, role: '' }
      })

      okerr.ok = true
    } catch (error) {
      const errorObject = errorObjectFromError(error as object)

      if (
        errorObject &&
        errorObject.action &&
        [Constants.PROMPT_USER].includes(errorObject.action)
      ) {
        errorObject.status = 'warning'
        okerr.error = errorObject

        setState((state: AuthStore) => {
          state.isLoading = false
          state.isUpgrading = true
        })
      } else {
        okerr.error = {
          message: 'requests.auth.signIn.error.message',
          type: Constants.HTTP_ERROR,
        }
      }
    }

    return new Promise(resolve => {
      resolve(okerr)
    })
  },
  signOut: () => {
    return AuthService.signOut()
      .then(() => {
        set({
          isAuthenticated: false,
          isAuthenticating: true,
          isSignedOut: true,
          user: undefined,
        })
      })
      .catch(() => {
        set({
          isAuthenticated: false,
          isAuthenticating: true,
          isSignedOut: true,
          user: undefined,
        })
      })
  },
  signUp: async (email: string, options: object) => {
    const okerr: OkErr = {
      ok: false,
    }
    const setState = (func: SetState) => set(produce(get(), func))

    setState((state: AuthStore) => {
      state.isCognito = true
    })

    try {
      const response = AuthService.signUp(email, options)
      await response

      setState((state: AuthStore) => {
        state.isLoading = false
        state.user = { email, role: '' }
      })

      okerr.ok = true
    } catch (error) {
      const errorObject = errorObjectFromError(error as object)

      if (
        errorObject &&
        errorObject.action &&
        [Constants.PROMPT_USER].includes(errorObject.action)
      ) {
        errorObject.status = 'warning'
        okerr.error = errorObject

        setState((state: AuthStore) => {
          state.isLoading = false
          state.isUpgrading = true
        })
      } else if (
        errorObject &&
        errorObject.action &&
        [Constants.CONFIRM_CODE].includes(errorObject.action)
      ) {
        errorObject.status = 'info'
        okerr.error = errorObject
      } else {
        okerr.error = {
          message: 'requests.auth.signUp.error.message',
          type: Constants.HTTP_ERROR,
        }
      }
    }

    return new Promise(resolve => {
      resolve(okerr)
    })
  },
  signUpBitbucket: () => {
    return AuthService.signUpBitbucket()
  },
  signUpGitHub: () => {
    return AuthService.signUpGitHub()
  },
  signUpGitLab: () => {
    return AuthService.signUpGitLab()
  },
  signUpGoogle: () => {
    return AuthService.signUpGoogle()
  },
}))
