import { ApplicationError } from '../@types/application'

export class PairViewModel {
  _error: ApplicationError | undefined
  _isLoading = true

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public set isLoading(value: boolean) {
    this._isLoading = value
  }
}
