import { AuthSession, AuthTokens } from 'aws-amplify/auth'
import { create } from 'zustand'

import { ProviderType } from '@app/@types/space'
import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

import {
  Account,
  AccountResponse,
  AccountStatus,
  Download,
  EditAccountResponse,
  Invitation,
  Invoice,
  Member,
  NewProvider,
  PaymentMethod,
  Plan,
  Price,
  Provider,
  StripeAction,
  StripeConfiguration,
  Subscription,
  SubscriptionPlan,
  UsageResponse,
  isSubscriptionPlan,
} from '../@types/account'
import { ApplicationError, OkErr } from '../@types/application'
import { ApplicationConstants as Constants } from '../constants'
import { AccountService, AuthService, ErrorService } from '../services'

// type SetState = (state: AccountStore) => void

const initialState = {
  account: undefined,
  accountNextAction: undefined,
  features: Constants.FEATURES,
  invitations: [],
  invoice: undefined,
  invoices: [],
  isLoading: false,
  isProvisioning: false,
  members: [],
  paymentMethod: undefined,
  period: 'month',
  plans: Constants[
    `PLANS${process.env.PLANS}` as keyof typeof Constants
  ] as Plan[],
  prices: Constants.PRICES,
  role: 'initial',
  status: AccountStatus.PENDING_PROVIDER,
  stripe: { clientSecret: '', publishableKey: '' },
  stripePromise: undefined,
  subscription: undefined,
  users: [],
}

export type AccountStore = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  account?: Account
  createInvitation: (invitation: Invitation) => Promise<unknown>
  createKeys: () => Promise<unknown>
  createProvider: (provider: NewProvider) => Promise<OkErr>
  createSubscription: (plan: SubscriptionPlan) => Promise<unknown>
  deleteAccount: () => Promise<unknown>
  deleteInvitation: (invitationId: string) => Promise<unknown>
  deleteSubscription: () => Promise<Response>
  deleteUser: (userId: string) => Promise<unknown>
  editAccount: () => Promise<unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  features: ReadonlyArray<Record<string, any>>
  getAccount: () => Promise<unknown>
  getAccountMembers: () => Promise<unknown>
  getAccountUsage: () => Promise<unknown>
  getPlans: () => Promise<unknown>
  getStripePromise: () => void
  getUsers: () => Promise<unknown>
  identities: () => Promise<AuthSession>
  invitations: Invitation[]
  invoice?: Invoice
  invoices: Download[]
  isCurrentPlan: (priceId?: string) => boolean
  isLoading: boolean
  isProvisioning: boolean
  lastFour: () => string | undefined
  members: (Invitation | Member)[]
  newSubscription: () => Promise<unknown>
  nextAction?: StripeAction
  paymentMethod?: PaymentMethod
  period: string
  plan: () => string | undefined
  planFor: (productId: string) => string
  plans: Plan[]
  priceForPlan: (plan: Plan) => string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prices: Record<string, any>
  providerForType: (providerType: ProviderType) => Provider | undefined
  role: string
  setIsLoading: (value: boolean) => void
  setIsProvisioning: (value: boolean) => void
  status: AccountStatus
  stripe: StripeConfiguration
  stripePromise?: Promise<Stripe | null>
  subscription?: Subscription
  togglePeriod: () => void
  updateAccount: () => Promise<unknown>
  updateInvitation: (
    accountId: string,
    invitationId: string
  ) => Promise<unknown>
  updateSubscription: (plan: SubscriptionPlan) => Promise<unknown>
  updateUser: (userId: string, role: string) => Promise<unknown>
  users: Member[]
}

export const useAccountStore = create<AccountStore>((set, get) => ({
  ...initialState,
  identities: () => {
    return AuthService.currentSession()
  },
  isCurrentPlan: (priceId?: string) => {
    const subscription = get().subscription

    if (typeof subscription == 'undefined') return false
    if (subscription.status == null) return false

    if (['canceled', 'incomplete_expired'].includes(subscription.status)) {
      if (typeof priceId == 'undefined') return true // Free plan

      return false
    }

    return priceId == subscription.plan.priceId
  },
  setIsLoading: (value: boolean) => {
    set({ isLoading: value })
  },
  setIsProvisioning: (value: boolean) => {
    set({ isProvisioning: value })
  },
  priceForPlan: (plan: Plan) => {
    const prices: Price[] = plan.prices
    let priceId = ''

    prices.forEach(price => {
      const period = price.recurring.interval
      if (period == get().period) {
        priceId = price.id
      }
    })

    return priceId
  },
  providerForType: () => {
    return get().account?.provider as Provider
  },
  togglePeriod: () => {
    set({ period: get().period == 'month' ? 'year' : 'month' })
  },
  // Accounts API
  deleteAccount: async () => {
    return await AuthService.currentSession().then((session: AuthSession) => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.deleteAccount(
        account?.id as string,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.accounts.delete.error.message',
            })
            reject(error)
          }
        })
      })
    })
  },
  editAccount: async () => {
    return await AuthService.currentSession().then(session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.editAccount(
        account?.id as string,
        accessToken.toString()
      )
        .then(response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = ErrorService.errorFor(response, {
                message: 'requests.accounts.edit.error.message',
              })
              reject(error)
            }
          })
        })
        .then((data: unknown) => {
          set({
            stripe: { ...(data as EditAccountResponse).stripe },
          })
        })
    })
  },
  getAccount: async () => {
    return await AuthService.currentSession()
      .then(session => {
        const { tokens } = session
        const { accessToken, idToken } = tokens as AuthTokens

        const payload = idToken?.payload
        const roles = payload && (payload['cognito:groups'] as string[])

        const account = JSON.parse(payload?.account as string)
        const role = roles && (roles[0] as string)

        return AccountService.getAccount(account.id, accessToken.toString())
          .then(response => {
            return new Promise((resolve, reject) => {
              if (response.ok) {
                resolve(response.json())
              } else {
                const error: ApplicationError = ErrorService.errorFor(
                  response,
                  {
                    message: 'requests.accounts.get.error.message',
                  }
                )
                reject(error)
              }
            })
          })
          .then(data => {
            const account = data as AccountResponse

            set({
              account: account.account,
              nextAction: account.account.nextAction,
              paymentMethod: account.paymentMethod,
              role,
              status: account.account.status,
              subscription: account.subscription,
            })

            // If account status is active, account was provisioned, else...
            if (account.account.status == AccountStatus.ACTIVE) {
              set({ isProvisioning: false })
            }

            // ...wait for provider to be provisioned...
            if (get().isProvisioning) {
              setTimeout(() => {
                get().getAccount()
              }, 5000)
            }
          })
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateAccount: async () => {
    const session = await AuthService.currentSession()
    const { tokens } = session
    const { accessToken, idToken } = tokens as AuthTokens

    const account = JSON.parse(idToken?.payload.account as string)

    return await AccountService.updateAccount(
      account.id,
      idToken?.toString() as string,
      accessToken.toString()
    ).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          const error: ApplicationError = ErrorService.errorFor(response, {
            message: 'requests.accounts.update.get.error.message',
          })
          reject(error)
        }
      })
    })
  },
  getAccountMembers: async () => {
    const session = await AuthService.currentSession()
    const { tokens } = session
    const { accessToken } = tokens as AuthTokens

    const account = get().account

    const response = await AccountService.getAccountMembers(
      account?.id as string,
      accessToken.toString()
    )

    if (response.ok) {
      const data: { users: Member[] } = await response.json()
      set({ members: data.users as Member[] })
    } else {
      // TODO
      const error: ApplicationError = ErrorService.errorFor(response, {
        message: 'requests.accounts.members.get.error.message',
      })
      console.log(error)
    }
  },
  getAccountUsage: async () => {
    const session = await AuthService.currentSession()
    const { tokens } = session
    const { accessToken } = tokens as AuthTokens

    const account = get().account

    const response = await AccountService.getAccountUsage(
      account?.id as string,
      accessToken.toString()
    )

    if (response.ok) {
      const data: unknown = response.json()
      set({
        invoice: (data as UsageResponse).invoice,
        invoices: (data as UsageResponse).invoices,
      })
    }

    // .then(response => {
    //   return new Promise((resolve, reject) => {
    //     if (response.ok) {
    //       resolve(response.json())
    //     } else {
    //       const error: ApplicationError = ErrorService.errorFor(response, {
    //         message: 'requests.accounts.usage.get.error.message',
    //       })
    //       reject(error)
    //     }
    //   })
    // })
  },
  getPlans: async () => {
    return await AccountService.getPlans().then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response.json())
        } else {
          reject()
        }
      })
    })
  },
  // Invitations API
  createInvitation: async (invitation: Invitation) => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.createInvitation(
        account?.id as string,
        invitation,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.users.create.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            invitations: Invitation[]
          }

          set({ invitations: users.invitations })
          set({
            members: [...users.invitations, ...get().users],
          })
        })
      })
    })
  },
  deleteInvitation: async (invitationId: string) => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      return AccountService.deleteInvitation(
        invitationId,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.invitations.delete.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            invitations: Invitation[]
          }
          set({ invitations: users.invitations })
          set({
            members: [...users.invitations, ...get().users],
          })
        })
      })
    })
  },
  updateInvitation: (accountId: string, invitationId: string) => {
    return AccountService.updateInvitation(accountId, invitationId).then(
      response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.users.create.error.message',
            })
            reject(error)
          }
        })
      }
    )
  },
  // Keys API
  createKeys: async () => {
    return await AuthService.currentSession().then(session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      return AccountService.createKeys(accessToken.toString())
    })
  },
  // Providers API
  createProvider: async (provider: NewProvider) => {
    const okerr: OkErr = {
      ok: false,
    }
    // const setState = (func: SetState) => set(produce(get(), func))

    try {
      const response = AuthService.currentSession()
      const session = await response

      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      const providerResponse = await AccountService.createProvider(
        account?.id as string,
        provider,
        accessToken.toString()
      )
      await providerResponse

      okerr.ok = true
    } catch (error) {
      okerr.error = {
        message: 'requests.provider.create.error.message',
        type: Constants.HTTP_ERROR,
      }
    }

    return new Promise(resolve => {
      resolve(okerr)
    })
  },
  // Stripe API
  getStripePromise: () => {
    if (typeof get().stripePromise == 'undefined') {
      const stripePromise = loadStripe(Constants.STRIPE_API_KEY as string)
      set({
        stripePromise,
      })
    }
  },
  // Subscriptions API
  createSubscription: async (plan: SubscriptionPlan) => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.createSubscription(
        account?.id as string,
        plan,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.subscriptions.create.error.message',
            })
            reject(error)
          }
        })
      })
    })
  },
  deleteSubscription: async () => {
    const session = await AuthService.currentSession()
    const { tokens } = session
    const { accessToken } = tokens as AuthTokens

    const account = get().account

    return AccountService.deleteSubscription(
      account?.id as string,
      accessToken.toString()
    ).then(response => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve(response)
        } else {
          const error: ApplicationError = ErrorService.errorFor(response, {
            message: 'requests.subscriptions.delete.error.message',
          })
          reject(error)
        }
      })
    })
  },
  newSubscription: async () => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.newSubscription(
        account?.id as string,
        accessToken.toString()
      )
        .then(response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = ErrorService.errorFor(response, {
                message: 'requests.subscriptions.new.error.message',
              })
              reject(error)
            }
          })
        })
        .then((data: unknown) => {
          set({
            stripe: { ...(data as EditAccountResponse).stripe },
          })
        })
    })
  },
  updateSubscription: async (plan: SubscriptionPlan) => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.updateSubscription(
        account?.id as string,
        plan,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.subscriptions.update.error.message',
            })
            reject(error)
          }
        })
      })
    })
  },
  // Users API
  deleteUser: async (userId: string) => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.deleteUser(
        account?.id as string,
        userId,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.users.delete.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            users: Member[]
          }

          set({ invitations: get().invitations })
          set({
            members: [...get().invitations, ...users.users],
          })
          set({ users: users.users })
        })
      })
    })
  },
  getUsers: async () => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.getUsers(
        account?.id as string,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.users.get.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            invitations: Invitation[]
            users: Member[]
          }

          set({ invitations: users.invitations })
          set({
            members: [...users.invitations, ...users.users],
          })
          set({ users: users.users })
        })
      })
    })
  },
  updateUser: async (userId: string, role: string) => {
    return await AuthService.currentSession().then(async session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      const account = get().account

      return AccountService.updateUser(
        account?.id as string,
        userId,
        role,
        accessToken.toString()
      ).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response.json())
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.users.update.error.message',
            })
            reject(error)
          }
        }).then((members: unknown) => {
          const users = members as {
            users: Member[]
          }

          set({
            members: [...get().invitations, ...users.users],
          })
          set({ users: users.users })
        })
      })
    })
  },
  lastFour: () => {
    return get().paymentMethod?.last4
  },
  plan: () => {
    let planName = undefined
    if (typeof get().account == 'undefined') {
      return planName
    }

    const { plan, status } = get().subscription as {
      plan: SubscriptionPlan
      status: string
    }

    if (['active', 'incomplete'].includes(status)) {
      if (isSubscriptionPlan(plan)) {
        planName = get().planFor(plan.productId as string)
      }
    } else {
      planName = 'free'
    }

    return planName
  },
  planFor: (productId: string) => {
    let planName = 'free'
    const key = `PLANS${process.env.PLANS}` as keyof typeof Constants
    const plans = Constants[key] as Plan[]

    plans.forEach(plan => {
      const planProductId = plan.product.id
      if (planProductId == productId) {
        planName = plan.name
      }
    })

    return planName
  },
}))
