import * as React from 'react'
import { Helmet } from 'react-helmet'

import { ChakraProvider } from '@chakra-ui/react'
import { ReactLocation, Router } from '@tanstack/react-location'
import { Navigate } from '@tanstack/react-location'

import { CountdownPosterProvider } from './app/providers/PosterProvider'
import { PlausibleProvider } from './hooks/usePlausible'
import { venvMarketingTheme } from './themes'

const location = new ReactLocation()

export const Countdown = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          http-equiv="Content-Security-Policy"
          content="
            default-src 'self' https:;
            connect-src 'self' https://cognito-idp.us-east-1.amazonaws.com https://api.stripe.com https://maps.googleapis.com https://plausible.io https://*.pairspaces.com https://pairspaces.com;
            frame-src 'self' https://js.stripe.com https://hooks.stripe.com;
            img-src 'self' https:;
            script-src 'self' https: https://js.stripe.com https://maps.googleapis.com 'sha256-RV6I4HWPb71LvA27WVD3cEz8GsJrHlfcM/2X2Q5gV00=';
            style-src 'self' https: 'unsafe-inline';"
        />
        <meta name="theme-color" content="#f8f8f8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#252e4e" />
        <meta name="apple-mobile-web-app-title" content="PairSpaces" />
        <meta name="application-name" content="PairSpaces" />
        <meta name="msapplication-TileColor" content="#252e4e" />
        <meta name="theme-color" content="#f8f8f8" />
        <script
          defer
          data-domain="pairspaces.com"
          src="https://plausible.io/js/script.js"
        ></script>
      </Helmet>
      <PlausibleProvider>
        <ChakraProvider theme={venvMarketingTheme}>
          <Router
            location={location}
            routes={[
              { path: '/', element: <CountdownPosterProvider /> },
              {
                element: <Navigate to="/" replace={true} />,
              },
            ]}
          />
        </ChakraProvider>
      </PlausibleProvider>
    </>
  )
}
