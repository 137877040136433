import { ApplicationError } from '../@types/application'

export const undefinedToNull = (
  variable: string | null | undefined
): string | null => {
  return variable === undefined ? null : variable
}

export const errorObjectFromError = (
  error: object
): ApplicationError | undefined => {
  const regex = /{.*}/
  const matches = error.toString().match(regex)

  if (matches && matches.length) {
    return JSON.parse(matches[0])
  }
}
