import { SubscriptionPlan } from '../@types/account'

type IAnalyticsService = {
  didAddBillingAddress: (plan: SubscriptionPlan) => void
  didAddPaymentMethod: () => void
  didEndExplainerVideo: () => void
  didLogin: () => void
  didSelectPlan: (plan: SubscriptionPlan) => void
  didSignUp: () => void
  didStartExplainerVideo: () => void
  didSubscribe: (plan: SubscriptionPlan) => void
  didViewDocumentation: () => void
  didViewHero: () => void
}

export class AnalyticsServiceClass implements IAnalyticsService {
  didAddBillingAddress() {
    window.plausible('AddBillingAddress', {
      props: {
        currency: 'USD',
        payment_type: 'Credit Card',
      },
    })
  }

  didAddPaymentMethod() {
    window.plausible('AddPaymentMethod', {
      props: {
        currency: 'USD',
        payment_type: 'Credit Card',
      },
    })
  }

  didEndExplainerVideo() {
    window.plausible('ViewedExplainerVideo')
  }

  didLogin() {
    window.plausible('Login')
  }

  didSelectPlan(plan: SubscriptionPlan) {
    window.plausible('SelectPlan', {
      props: {
        itemId: plan.priceId,
        item_name: plan.productId,
        quantity: 1,
      },
    })
  }

  didSignUp() {
    window.plausible('SignUp', {
      props: {
        method: 'email',
      },
    })
  }

  didStartExplainerVideo() {
    window.plausible('ViewExplainerVideo')
  }

  didSubscribe(plan: SubscriptionPlan) {
    window.plausible('Subscribe', {
      props: {
        item_id: plan.priceId,
        item_name: plan.productId,
        quantity: 1,
      },
    })
  }

  didViewDocumentation() {
    window.plausible('ViewDocumentation')
  }

  didViewHero() {
    window.plausible('ViewHero')
  }
}

export const AnalyticsService = new AnalyticsServiceClass()
