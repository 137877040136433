import { UseNavigateType } from '@tanstack/react-location'

import { ApplicationError } from '../@types/application'
import { ApplicationConstants as Constants } from '../constants'
import { AnalyticsService } from '../services/analyticsService'
import { AccountStore } from '../stores/accountStore'
import { ApplicationStore } from '../stores/applicationStore'
import { AuthStore } from '../stores/authStore'

export class ApplicationViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  authStore: AuthStore
  _error: ApplicationError | undefined

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    authStore: AuthStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.authStore = authStore
  }

  public get accountRequiresAction() {
    return this.accountStore.nextAction
  }

  public get accountStatus() {
    return this.accountStore.status
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  public get willShowBanner(): boolean {
    return this.applicationStore.willShowBanner
  }

  public documentation() {
    AnalyticsService.didViewDocumentation()

    window.open(Constants.DOCUMENTATION_URL as string, '_blank')
  }

  public isCurrentMenuItem(label: string): 'page' | false {
    return this.applicationStore.isCurrentMenuItem(label)
  }

  public navigateTo(path: string, navigate: UseNavigateType) {
    navigate({
      to: path,
      replace: false,
    })
  }

  public signOut(navigate: UseNavigateType) {
    this.authStore.signOut().then(() => {
      navigate({
        to: '/',
        replace: false,
      })
    })
  }

  closeBanner(navigate?: UseNavigateType) {
    this.applicationStore.willShowBanner = false

    if (typeof navigate != 'undefined') {
      navigate({
        to: '/accounts/billing',
        replace: false,
      })
    }
  }
}
