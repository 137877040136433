import { SignUpOutput } from 'aws-amplify/auth'

import { ApplicationError, ErrorOptions } from '../@types/application'
import {
  CognitoSessionResponse,
  CognitoSignUpResponse,
  CognitoUser,
  isCognitoSessionResponse,
  isCognitoSignUpResponse,
  isCognitoUser,
  isResponse,
  isSignUpResponse,
} from '../@types/external'
import { ApplicationConstants as Constants } from '../constants'

export type IErrorService = {
  errorFor: (
    response?:
      | Response
      | CognitoSessionResponse
      | CognitoSignUpResponse
      | CognitoUser
      | SignUpOutput,
    options?: ErrorOptions
  ) => ApplicationError
}

class ErrorServiceClass implements IErrorService {
  errorFor(
    response?:
      | Response
      | CognitoSessionResponse
      | CognitoSignUpResponse
      | CognitoUser
      | SignUpOutput
      | undefined
      | unknown,
    options?: ErrorOptions | undefined
  ): ApplicationError {
    if (
      isCognitoSessionResponse(response) ||
      isCognitoUser(response) ||
      isCognitoSignUpResponse(response) ||
      isSignUpResponse(response)
    ) {
      return {
        message: options?.message,
        type: Constants.HTTP_ERROR,
      }
    } else if (isResponse(response)) {
      return {
        message: options?.message,
        status: 'error',
        type: Constants.HTTP_ERROR,
      }
    } else {
      return {
        message: options?.message,
        type: Constants.APPLICATION_ERROR,
      }
    }
  }
}

export const ErrorService = new ErrorServiceClass()
