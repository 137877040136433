type IPassword = {
  generate: (length: number) => string
}

export const usePassword = (): IPassword => {
  /* eslint-disable-next-line no-useless-escape */
  const _pattern = /[a-zA-Z0-9_\-\+\.]/

  const _getRandomByte = function () {
    if (window && window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1)
      window.crypto.getRandomValues(result)
      return result[0]
      // } else if (window && window.msCrypto && window.msCrypto.getRandomValues) {
      //   const result = new Uint8Array(1)
      //   window.msCrypto.getRandomValues(result)
      //   return result[0]
    } else {
      return Math.floor(Math.random() * 256)
    }
  }

  const generate = function (length: number) {
    const baseArray = new Array(length)
    const constantCondition = true
    // eslint-disable-next-line prefer-spread
    return Array.apply(null, baseArray)
      .map(function () {
        let result
        while (constantCondition) {
          result = String.fromCharCode(_getRandomByte())
          if (_pattern.test(result)) {
            return result
          }
        }
      })
      .join('')
  }

  return {
    generate,
  }
}
