import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import { PosterProps } from '../../../app/@types/poster'
import { DurationSwitcher } from '../DurationSwitcher'
// import { PlanCallToAction } from './PlanCallToAction'
import { PlanFeaturesTable } from './PlanFeaturesTable'
import { PlanOverviewTable } from './PlanOverviewTable'

export const Pricing = (props: PosterProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { t } = useTranslation()

  return (
    <Box as="section" py={{ base: '16', md: '24' }}>
      <Stack spacing={{ base: '16', md: '24' }} shouldWrapChildren>
        <Container maxW="5xl">
          <Stack
            spacing={{ base: '4', md: '6' }}
            textAlign="center"
            align="center"
          >
            <Stack spacing="3">
              <Heading fontSize={{ base: '4xl', md: '5xl' }}>
                {t(`posters.common.pricing.heading`)}
              </Heading>
            </Stack>
            <Text fontSize={{ base: 'lg', md: '2xl' }} lineHeight="1.25">
              {t(`posters.common.pricing.subHeading`)}
            </Text>
            {isMobile ? <DurationSwitcher {...props} /> : <></>}
          </Stack>
        </Container>
        {isMobile ? (
          <Stack spacing="16">
            {props.viewModel.plans.map((plan, id) => (
              <Container px={{ base: '0' }} key={id}>
                <Stack spacing={{ base: '5', md: '7' }}>
                  <PlanOverviewTable {...props} plans={[plan]} />
                  <PlanFeaturesTable {...props} plans={[plan]} />
                  {/* <PlanCallToAction {...props} plans={[plan]} /> */}
                </Stack>
              </Container>
            ))}
          </Stack>
        ) : (
          <Container px={{ base: '0', lg: '8' }} maxW="5xl">
            <Stack spacing={{ base: '5', md: '7' }}>
              <PlanOverviewTable {...props} plans={props.viewModel.plans} />
              <PlanFeaturesTable {...props} plans={props.viewModel.plans} />
              {/* <PlanCallToAction {...props} plans={props.viewModel.plans} /> */}
            </Stack>
          </Container>
        )}
      </Stack>
    </Box>
  )
}
