import { StripeError } from '@stripe/stripe-js'

import { ExternalViewModel } from '../viewModels/externalViewModel'
import { PairViewModel } from '../viewModels/pairViewModel'

type AccessTokenType = {
  payload: unknown
  toString: () => string
}

type IdTokenType = {
  payload: unknown
}

type TokenType = {
  accessToken: AccessTokenType
  idToken: IdTokenType
}

export type CognitoSession = {
  tokens: TokenType
}

export type Identity = {
  providerName: string
  userId: string
}

export type CognitoPayloadAccount = {
  awsAccountId: string
  id: string
  organization: boolean
  status: string
}

export type CognitoSessionResponse = {
  signInUserSession: CognitoSession
}

export type CognitoUser = {
  authenticationFlowType: string
  client: string
  keyPrefix: string
  pool: string
  userDataKey: string
  username: string
}

export type CognitoSignUpResponse = {
  user: CognitoUser
  userConfirmed: boolean
  userSub: string
}

export type StripeBillingInfo = {
  address: {
    city: string | undefined
    country?: string
    line1?: string
    line2?: string
    postal_code: string
    state: string
  }
  firstName?: string
  lastName?: string
  name: string
  phone?: string
}

export type StripeConfirmSetupResponse = {
  error?: StripeError
  setupIntent?: unknown
}

export const isCognitoSessionResponse = (
  value: unknown
): value is CognitoSessionResponse => {
  if ((value as CognitoSessionResponse).signInUserSession) {
    return true
  }
  return false
}

export const isCognitoSignUpResponse = (
  value: unknown
): value is CognitoSignUpResponse => {
  if ((value as CognitoSignUpResponse).user) {
    return true
  }
  return false
}

export const isCognitoUser = (value: unknown): value is CognitoUser => {
  if ((value as CognitoUser).username) {
    return true
  }
  return false
}

export const isResponse = (value: unknown): value is Response => {
  if ((value as Response).status) {
    return true
  }
  return false
}

export const isSignUpResponse = (value: unknown): value is Response => {
  if ((value as Response).status) {
    return true
  }
  return false
}

export type ExternalViewProps = {
  viewModel: ExternalViewModel
}

export type PairViewProps = {
  viewModel: PairViewModel
}
