import * as React from 'react'

import { PosterProps } from '../app/@types/poster'
import {
  CTALarge,
  Features,
  Footer,
  HeroWithImage,
  NavBar,
  Pricing,
  Statistics,
} from '../components/Posters'
import { Faqs } from '../components/Posters/FAQ'
import { Gallery } from '../components/Posters/Quotes'

export const LandingPoster = (props: PosterProps) => {
  return (
    <>
      <NavBar {...props} />
      <HeroWithImage {...props} />
      <Features {...props} level={1} />
      <CTALarge {...props} level={2} staticImage={false} />
      <Pricing {...props} />
      <Faqs {...props} />
      <CTALarge {...props} level={1} staticImage={true} />
      {/* <Testimonial {...props} /> */}
      <Gallery {...props} />
      <Statistics {...props} />
      <Footer {...props} />
    </>
  )
}
