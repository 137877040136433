import * as React from 'react'

import { Box, Center, Stack, Text, useColorModeValue } from '@chakra-ui/react'

import { Timer } from '../components/Posters/Timer'

export const CountdownPoster = () => {
  return (
    <Center height="100vh">
      <Box as="section" pt="8" pb="12" width="full">
        <Box
          bg={useColorModeValue('black', 'gray.700')}
          color="white"
          position="relative"
        >
          <Box
            maxW="7xl"
            mx="auto"
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '3', md: '2.5' }}
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              align="center"
              justify="center"
              spacing={{ base: '2', md: '20', lg: '7.5rem' }}
            >
              <Text fontWeight="medium" fontSize="xl">
                PairSpaces. Coming Soon.
              </Text>
              <Timer />
            </Stack>
          </Box>
        </Box>
      </Box>
    </Center>
  )
}
