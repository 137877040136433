import { ApplicationConstants as Constants } from '../constants'

type TextObject = {
  text?: string
}

type ContentObject = Partial<TextObject>

type ContentStructureKeys =
  | 'about'
  | 'agency'
  | 'collaboration'
  | 'enterprise'
  | 'productivity'
  | 'security'
  | 'startup'

type ContentStructure = {
  about: ContentObject[]
  agency: ContentObject[]
  collaboration: ContentObject[]
  enterprise: ContentObject[]
  productivity: ContentObject[]
  security: ContentObject[]
  startup: ContentObject[]
}

export class BlogViewModel {
  _name = 'agency' as ContentStructureKeys

  public documentation() {
    window.open(Constants.DOCUMENTATION_URL, '_blank')
  }

  public linkedText(index: number): { link?: string; text?: string } {
    return (
      {
        about: [
          {},
          {},
          {},
          {},
          {
            text: 'A PairSpace is a virtual environment that your team uses to code together in real-time. They are the most secure type of development environment, because they are the first to use zero-trust networks, replacing the conventional systems for securing virtual environments with a modern and better approach (<1 href="/pages/security" target="_default">here</1>). PairSpaces saves your team days and thousands of dollars per member every year and they are the best way to build products and build your team: Research shows 96% of teams that collaborate are happier and 95% are more confident in what they ship (<1 href="/pages/collaboration" target="_default">link</1>).',
          },
          {
            text: "PairSpaces's mission is to help people work together in the cloud. And to do it securely and easily. Learn how we make teams more productive (<1 href='/pages/productivity' target='_default'>link</1>), collaborative (<1 href='/pages/collaboration' target='_default'>link</1>) and secure (<1 href='/pages/security' target='_default'>link</1>).",
          },
        ],
        agency: [
          {},
          {
            text: 'PairSpaces is the first development environment that enables real-time collaboration for software teams. Using PairSpaces, a team can work together from anywhere in the world, collaborating on the same project, at the same time, in the same instance. Surveys show that 96% of software teams enjoy working together versus alone and 95% of software teams feel more confident in their work. To learn more about how PairSpaces enables real-time collaboration see our post',
          },
          {
            text: 'On average, PairSpaces saves several days and thousands of dollars per software developer every year. This means your teams will be more productive this year compared to last year and your clients will see the difference. PairSpaces, because they enable real-time collaboration, make onboarding teams to new projects and onboarding new team members to existing projects faster than ever before. To learn how PairSpaces provide value to your clients, read our post on productivity',
          },
          {},
        ],
        collaboration: [
          {
            text: 'PairSpaces is the first development environment that uses zero-trust networks to provide secure, real-time collaboration. (First time reading about zero-trust networks? Read us on it <1 href="/pages/security">here</1>.) PairSpaces is more secure than working from your laptop, because PairSpaces authenticates users using remote policies, not secret credentials, keys, or tokens that exist on your laptop.',
          },
          {},
          {},
          {
            text: 'There is even a name given to coding together: "Pair Programming." Pair programmers report enjoying their work more than working alone (96% of survey group) and feel more confident in their work (95% of survey group; <1 href="http://sunnyday.mit.edu/16.355/williams.pdf" target="_blank">link</1>). You can learn more about Pair Programming from one of the industry\'s thought leaders, Martin Fowler (<1 href="https://martinfowler.com/articles/on-pair-programming.html" target="_blank">link</1>).',
          },
          {},
          {},
          {
            text: 'PairSpaces is the first development environment that enables real-time collaboration. One of the key benefits is your team spends more time shipping higher quality code. This means less time explaining code during code review and less money wasted on building code using continuous integration tools only to have to correct the code. Learn how PairSpaces saves you time and money <1 href="/pages/productivity">here</1>.',
          },
        ],
        enterprise: [
          {},
          {},
          {},
          {
            text: 'PairSpaces is the first secure development environment for pair programming. Research shows pair programming saves 15X the development cost when bugs are discovered during testing and 60X the cost when bugs are discovered by customers. More research shows that the error rate of code produced by teams was three orders of magnitude lower when teams coded together. And using PairSpaces, you benefit from a workflow for pair programming that is compatible with Git and Mercurial. Read more from us on pair programming <1 href="/blog/collaboration" target="_default">here</1> and how PairSpaces improves collaboration <1 href="/pages/collaboration" target="_default">here</1>.',
          },
          {
            text: 'Using PairSpaces, software teams avoid costly build and test phases that result in change requests, because the code review occurs when the code is written. This means a team saves more than $1000 per person for every change request. You can estimate the time and money saved for your team using our free tool <1 href="/pages/savings" target="_default">here</1>.',
          },
        ],
        productivity: [
          {
            text: 'Software development moves through different stages of a lifecycle that include building or compiling, testing, and deploying (<1 href="https://aws.amazon.com/what-is/sdlc/">link</1>). A software team uses processes like continuous integration that automatically build and test software when it is committed to version control for review. Each stage is an opportunity to wait for the results of building and testing software or for comments from other team members. This wait time costs money when a team either waits or switches between contexts to other tasks. GitHub measured the cost of this wait time <2 href="https://github.blog/2022-12-08-experiment-the-hidden-costs-of-waiting-on-slow-build-times/">here</2>.',
          },
          {
            text: 'PairSpaces enables real-time collaboration between team members sitting in the same space or collaborating from the other side of the world, and they make it possible - for the first time - for teams to review code while the code is being written. This means teams can avoid waiting for build and test stages to complete only to discover that they is an issue with their code that requires a change and another build and test phase. (There are other advantages to real-time collaboration and we describe them <1 href="/pages/collaboration">here</1>.)',
          },
          {
            text: 'According to CircleCI\'s State of Software Delivery report in 2023 (<1 href="https://circleci.com/resources/2023-state-of-software-delivery" target="_blank">link</1>), every build and test phase takes 3.3 minutes. If only one phase results in a change a software team loses almost 2 days of productivity per person every year. Using StackOverflow\'s Developer Survey in 2022 (<1 href="https://survey.stackoverflow.co/2022/#salary-united-states" target="_blank">link</1>) to obtain the average software developer salary in the US - $150,000 - means your business loses at least $1000 per person every year. And this number multiplies if your team averages two or more change requests every day.',
          },
          {
            text: 'Using PairSpaces, software teams avoid build and test phases that result in change requests, because the code review occurs when the code is written. This means a team saves more than $700 per person for every change request. You can calculate the time and money saved for your team using our Productivity Calculator <1 href="/pages/savings" target="_blank">here</1>.',
          },
          {
            text: 'PairSpaces is the first development environment that uses zero-trust networks to secure your code and data and create an incredible developer experience. And, for the first time, PairSpaces enables real-time collaboration for software teams from anywhere in the world. Learn more about our approach to security <1 href="/pages/security" target="_blank">here</1> and collaboration <1 href="/pages/collaboration" target="_blank">here</1>.',
          },
        ],
        security: [
          {},
          {},
          {},
          {
            text: 'These conventional processes have been developed and optimized by companies like Facebook (<1 href="https://engineering.fb.com/2016/09/12/security/scalable-and-secure-access-with-ssh/" target="_blank">link</1>) and Netflix ((<1 href="https://github.com/Netflix/bless" target="_blank">link</1>)). These companies use expiring SSH certificates that replace SSH keys with a single key that is signed by a trusted intermediary. Both the employee and the server exchange their SSH certificates and agree they are authentic if both certificates are signed by the same trusted intermediary.',
          },
          {},
          {
            text: 'In response to both the need for continued investment and persistent risks, AWS introduced Verified Access (<1 href="https://aws.amazon.com/verified-access/" target="_blank">link</1>) and Google created the BeyondCorp model that uses zero-trust networks to secure access to internal networks (<1 href="https://cloud.google.com/beyondcorp/" target="_blank">link</1>). These alternatives provide a way for businesses to move to zero-trust networks, but not for developing software.',
          },
          {
            text: 'Forrester, who introduced the term in 2010, provide a definition of zero-trust networks (<1 href="https://www.forrester.com/blogs/the-definition-of-modern-zero-trust/" target="_blank>link</1>): Zero-trust networks must demonstrate three essential characteristics: (i) Zero-trust is the default, i.e., access is denied by default, (ii) Least privilege access is enforced and (iii) Continuous security monitoring. These characteristics represent both a simpler and more secure approach to network security than conventional software-defined perimeter approaches.',
          },
          {},
          {
            text: 'PairSpaces creates zero-trust networks that replace the high cost of infrastructure and processes used to secure an engineering team’s access to their development environments. PairSpaces also reduces the cost of building software by providing real-time collaboration across your team: an average team can save 2 days and $1000 per person every year. Learn how PairSpaces enables collaboration <1 href="/pages/collaboration" target="_blank">here</1> and save you time and money <1 href="/pages/productivity" target="_blank">here</1>.',
          },
        ],
        startup: [
          {},
          {
            text: 'On average, PairSpaces saves several days and thousands of dollars per software developer every year. This means you will be more productive than your competition. PairSpaces, because they enable real-time collaboration, make onboarding new hires to projects faster than ever before. To learn how PairSpaces provides value to your startup, read our post on productivity',
          },
          {
            text: 'PairSpaces is the first development environment that enables real-time collaboration for software teams. Using PairSpaces, a team can work together from anywhere in the world, collaborating on the same project, at the same time, in the same instance. Surveys show that 96% of software teams enjoy working together versus alone and 95% of software teams feel more confident in their work. To learn more about how PairSpaces enables real-time collaboration see our post',
          },
          {},
        ],
      } as ContentStructure
    )[this.name][index]
  }

  public get name(): ContentStructureKeys {
    return this._name
  }

  public set name(value: ContentStructureKeys) {
    this._name = value
  }
}
