import { AuthTokens } from 'aws-amplify/auth'
import _ from 'lodash'
import { create } from 'zustand'

import { ApplicationConstants as Constants } from '@app/constants'

import { ApplicationError } from '../@types/application'
import { NewSpace, Space, SpaceStatus, SpaceUser } from '../@types/space'
import { AuthService, ErrorService, SpaceService } from '../services'

const initialState = {
  spaces: [],
  spaceUsers: [],
}

export type SpaceStore = {
  createSpace: (space: NewSpace) => Promise<unknown>
  deleteSpace: (id: string) => Promise<unknown>
  getSpaces: () => Promise<unknown>
  shareSpace: (id: string, values: SpaceUser[]) => Promise<unknown>
  spaceUsers: string[]
  spaces: Space[]
  watchSpaces: () => void
}

export const useSpaceStore = create<SpaceStore>((set, get) => ({
  ...initialState,
  createSpace: (space: NewSpace) => {
    return AuthService.currentSession().then(session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      return SpaceService.create(space, accessToken.toString()).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response)

              setTimeout(() => {
                get().getSpaces()
              }, Constants.POLL_INTERVAL)
            } else {
              const error: ApplicationError = ErrorService.errorFor(response, {
                message: 'requests.spaces.create.error.message',
              })
              reject(error)
            }
          })
        }
      )
    })
  },
  deleteSpace: (id: string) => {
    return AuthService.currentSession().then(session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      return SpaceService.delete(id, accessToken.toString()).then(response => {
        return new Promise((resolve, reject) => {
          if (response.ok) {
            resolve(response)

            setTimeout(() => {
              get().getSpaces()
            }, Constants.POLL_INTERVAL)
          } else {
            const error: ApplicationError = ErrorService.errorFor(response, {
              message: 'requests.spaces.delete.error.message',
            })
            reject(error)
          }
        })
      })
    })
  },
  getSpaces: async () => {
    return await AuthService.currentSession().then(session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      return SpaceService.list(accessToken.toString())
        .then(response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              return resolve(response.json())
            } else {
              const error: ApplicationError = ErrorService.errorFor(response, {
                message: 'requests.spaces.delete.error.message',
              })
              reject(error)
            }
          })
        })
        .then((data: unknown) => {
          const spaces = data as Space[]
          set({ ...initialState, spaces })

          if (
            _.some(spaces, space => {
              return (
                space.status &&
                [SpaceStatus.DELETING, SpaceStatus.DEPLOYING].includes(
                  space.status
                )
              )
            })
          ) {
            setTimeout(() => {
              get().getSpaces()
            }, Constants.POLL_INTERVAL)
          }
        })
    })
  },
  shareSpace: (id: string, values: SpaceUser[]) => {
    return AuthService.currentSession().then(session => {
      const { tokens } = session
      const { accessToken } = tokens as AuthTokens

      return SpaceService.share(id, values, accessToken.toString()).then(
        response => {
          return new Promise((resolve, reject) => {
            if (response.ok) {
              resolve(response.json())
            } else {
              const error: ApplicationError = ErrorService.errorFor(response, {
                message: 'requests.spaces.share.error.message',
              })
              reject(error)
            }
          })
        }
      )
    })
  },
  watchSpaces: () => {
    if (
      _.some(get().spaces, ['status', 'deleting']) ||
      _.some(get().spaces, ['status', 'deploying'])
    ) {
      const id = setInterval(() => {
        get().getSpaces()
      }, 15000)

      return () => clearInterval(id)
    }
  },
}))
