import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'

export const CTA = (props: PosterProps) => {
  const { t } = useTranslation()
  return (
    <Container m={0} py={{ base: '16', md: '24' }} width="100%" maxW="100%">
      <Box
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        borderRadius="xl"
        px={{ base: '6', lg: '16' }}
        py={{ base: '10', lg: '16' }}
      >
        <Stack
          spacing="8"
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
        >
          <Stack spacing="4" maxW="2xl">
            <Heading fontSize={useBreakpointValue({ base: 'lg', md: '5xl' })}>
              {t(`posters.${props.viewModel.name}.ctaSmall.heading`)}
            </Heading>
            <Text
              color="muted"
              fontSize={useBreakpointValue({ base: 'lg', lg: '3xl' })}
            >
              {t(`posters.${props.viewModel.name}.ctaSmall.subHeading`)}
            </Text>
          </Stack>
          <Stack
            spacing="3"
            direction={{ base: 'column', sm: 'row' }}
            justify={{ base: 'start' }}
          >
            <Button
              as={Link}
              to={props.viewModel.ctaSmallLinks[props.viewModel.name].secondary}
              hidden={
                t(`posters.${props.viewModel.name}.ctaSmall.secondaryCTA`) ==
                null
              }
              variant="secondary"
              size="lg"
            >
              {t(`posters.${props.viewModel.name}.ctaSmall.secondaryCTA`)}
            </Button>
            <Button
              as={Link}
              to={props.viewModel.ctaSmallLinks[props.viewModel.name].primary}
              variant="primary"
              size="lg"
            >
              {t(`posters.${props.viewModel.name}.ctaSmall.primaryCTA`)}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  )
}
