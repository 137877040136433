import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'

import {
  Box,
  Circle,
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import { PosterProps } from '../../app/@types/poster'
import { Carousel, CarouselSlide, useCarousel } from './../Carousel'
import { QuoteIcon } from './QuoteIcon'
import { Quotee } from './Quotee'

export const Gallery = (props: PosterProps) => {
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)

  const quotes = []

  for (let i = 0; i < props.viewModel.testimonialLength; i++) {
    quotes.push({
      name: `posters.${props.viewModel.name}.testimonials.quotes.${i}.name`,
      quote: `posters.${props.viewModel.name}.testimonials.quotes.${i}.quote`,
      title: `posters.${props.viewModel.name}.testimonials.quotes.${i}.title`,
    })
  }

  const [ref, slider] = useCarousel({
    slideChanged: slider => setCurrentSlide(slider.track.details.rel),
  })

  const hasPrevious = currentSlide !== 0
  const hasNext = currentSlide < props.viewModel.testimonialLength - 1

  return (
    <Stack spacing="4">
      <Box
        position="relative"
        py={{ base: '16', md: '48' }}
        sx={{
          _hover: {
            '> button': {
              display: 'inline-flex',
            },
          },
        }}
      >
        <Carousel ref={ref}>
          {quotes.map((quote, i) => (
            <CarouselSlide key={i}>
              <Box as="section" bg={useColorModeValue('gray.50', 'gray.800')}>
                <Box
                  maxW="3xl"
                  mx="auto"
                  px={{ base: '6', md: '8' }}
                  pt="12"
                  pb="16"
                >
                  <Flex direction="column" align="center" textAlign="center">
                    <QuoteIcon
                      color={useColorModeValue('gray.300', 'gray.600')}
                      fontSize={{ base: '3xl', md: '6xl' }}
                    />
                    <Text
                      fontSize={{ base: 'xl', md: '2xl' }}
                      fontWeight="medium"
                      mt="6"
                    >
                      {t(quote.quote)}
                    </Text>
                    <Quotee
                      name={t(quote.name)}
                      jobTitle={t(quote.title)}
                      mt="8"
                    />
                  </Flex>
                </Box>
              </Box>
            </CarouselSlide>
          ))}
        </Carousel>
        {hasPrevious && (
          <CarouselIconButton
            pos="absolute"
            left="3"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.prev()}
            icon={<IoChevronBackOutline />}
            aria-label="Previous Slide"
          />
        )}

        {hasNext && (
          <CarouselIconButton
            pos="absolute"
            right="3"
            top="50%"
            transform="translateY(-50%)"
            onClick={() => slider.current?.next()}
            icon={<IoChevronForwardOutline />}
            aria-label="Next Slide"
          />
        )}
        <HStack position="absolute" width="full" justify="center" bottom={8}>
          {quotes.map((_, index) => (
            <Circle
              key={index}
              size="2"
              bg={currentSlide === index ? 'gray.700' : 'blackAlpha.400'}
            />
          ))}
        </HStack>
      </Box>
    </Stack>
  )
}

const CarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    display="none"
    fontSize="lg"
    isRound
    boxShadow="base"
    bg={useColorModeValue('white', 'gray.800')}
    _hover={{
      bg: useColorModeValue('gray.100', 'gray.700'),
    }}
    _active={{
      bg: useColorModeValue('gray.200', 'gray.600'),
    }}
    _focus={{ boxShadow: 'inerhit' }}
    _focusVisible={{ boxShadow: 'outline' }}
    {...props}
  />
)
