import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FiInfo } from 'react-icons/fi'

import {
  Flex,
  HStack,
  Icon,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'

import { PosterProps } from '../../../app/@types/poster'
import { Feature } from '../../Plan/Feature'

type PlanFeaturesTableProps = Record<string, unknown> & PosterProps & TableProps

export const PlanFeaturesTable = (props: PlanFeaturesTableProps) => {
  const { ...tableProps } = props
  const { t } = useTranslation()

  return (
    <>
      {props.viewModel.features.map((feature, featureId) => (
        <Table
          key={featureId}
          sx={{ tableLayout: 'fixed' }}
          variant="striped"
          {...tableProps}
          colorScheme="gray"
        >
          <Thead>
            <Tr>
              <Th
                colSpan={props.plans && props.plans.length + 1}
                color="accent"
                fontSize="sm"
              >
                {t(
                  `posters.common.pricing.categories.${feature.category}.name`
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {feature.items.map((item, id) => (
              <Tr key={id}>
                <Td fontWeight="semibold">
                  <HStack spacing="1">
                    <Text fontWeight="semibold">
                      {t(
                        `posters.common.pricing.categories.${feature.category}.${item.name}.name`
                      )}
                    </Text>
                    {item.tooltip && (
                      <Tooltip
                        label={t(
                          `posters.common.pricing.categories.${feature.category}.${item.name}.tooltip`
                        )}
                        placement="bottom-start"
                      >
                        <Flex justify="center">
                          <Icon
                            as={FiInfo}
                            boxSize="4"
                            color="muted"
                            alignSelf="bottom"
                          />
                        </Flex>
                      </Tooltip>
                    )}
                  </HStack>
                </Td>
                {props.plans &&
                  props.plans.map((plan, id) => (
                    <Td
                      key={id}
                      textAlign={
                        props.plans &&
                        props.plans.slice(1, props.plans && props.plans.length)
                          .length > 1
                          ? 'center'
                          : 'right'
                      }
                      height="16"
                      color="muted"
                    >
                      <Feature value={plan.features[item.name]} />
                    </Td>
                  ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      ))}
    </>
  )
}
