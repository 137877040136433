import { loadStripe } from '@stripe/stripe-js'

import { ApplicationError } from '../@types/application'
import { ApplicationConstants as Constants } from '../constants'
import { AnalyticsService } from '../services/analyticsService'
import { AccountStore } from '../stores/accountStore'

export class ExternalViewModel {
  accountStore: AccountStore
  _error: ApplicationError | undefined

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  async confirmPaymentIntent(
    paymentIntentClientSecret: string,
    navigate: (options: unknown) => void
  ): Promise<unknown> {
    const stripe = await loadStripe(Constants.STRIPE_API_KEY)

    return stripe
      ?.retrievePaymentIntent(paymentIntentClientSecret)
      .then(({ paymentIntent }) => {
        const paymentIntentStatus = paymentIntent?.status

        if (paymentIntentStatus == 'succeeded') {
          navigate({ to: '/accounts/billing', replace: false })
        }

        if (paymentIntentStatus == 'requires_payment_method') {
          this.error = {
            message: 'requests.stripe.paymentIntent.get.error.message',
            type: 'application_error',
          }
          navigate({ to: '/accounts/billing', replace: false })
        }
      })
  }

  async confirmSetupIntent(
    setupIntentClientSecret: string,
    hashedSubscriptionPlan: string,
    navigate: (options: unknown) => void
  ): Promise<unknown> {
    const stripe = await loadStripe(Constants.STRIPE_API_KEY)

    return stripe
      ?.retrieveSetupIntent(setupIntentClientSecret)
      .then(({ setupIntent }) => {
        const setupIntentStatus = setupIntent?.status

        if (setupIntentStatus == 'succeeded') {
          if (hashedSubscriptionPlan) {
            const subscriptionPlanString = window.atob(hashedSubscriptionPlan)
            const subscriptionPlan = JSON.parse(subscriptionPlanString)
            subscriptionPlan.paymentMethod = setupIntent?.payment_method

            this.accountStore
              .createSubscription(subscriptionPlan)
              .then(() => {
                AnalyticsService.didSubscribe(subscriptionPlan)
                navigate({ to: '/accounts/billing', replace: false })
              })
              .catch((error: ApplicationError) => {
                this.error = error
              })
          } else {
            navigate({ to: '/accounts/billing', replace: false })
          }
        }

        if (setupIntentStatus == 'processing') {
          this.error = {
            message: 'requests.stripe.setupIntent.pending.error.message',
            type: 'application_error',
          }
        }

        if (setupIntentStatus == 'requires_payment_method') {
          this.error = {
            message: 'requests.stripe.setupIntent.get.error.message',
            type: 'application_error',
          }
          navigate({ to: '/accounts/billing', replace: false })
        }
      })
  }
}
