import * as React from 'react'

import { CountdownPoster } from '../../posters/Countdown'
import { EmailPoster } from '../../posters/Email'
import { LandingPoster } from '../../posters/Landing'
import { useAccountStore } from '../stores'
import { PosterViewModel } from '../viewModels'

export const CountdownPosterProvider = () => {
  return <CountdownPoster />
}

export const LandingPosterProvider = () => {
  const accountStore = useAccountStore()

  const viewModel = new PosterViewModel(accountStore)
  viewModel.name = 'landing'

  return <LandingPoster viewModel={viewModel} />
}

export const EmailPosterProvider = () => {
  const accountStore = useAccountStore()

  const viewModel = new PosterViewModel(accountStore)
  viewModel.name = 'email'

  return <EmailPoster viewModel={viewModel} />
}
