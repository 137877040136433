export { Hero } from './Hero'
export { HeroWithImage } from './HeroWithImage'
export { HeroWithVideo } from './HeroWithVideo'
export { CTA as CTALarge } from './CTA_large'
export { CTA as CTASmall } from './CTA_small'
export { Features } from './Features'
export { Footer } from './Footer'
export { Header } from './Header'
export { NavBar } from './NavBar'
export { Pricing } from './Pricing'
export { Statistics } from './Statistics'
export { Testimonial } from './Testimonial'
