import * as React from 'react'
import { FaCodeBranch, FaPython } from 'react-icons/fa'
import TypeIt from 'typeit'

import { Box, BoxProps, HStack, Icon, Text } from '@chakra-ui/react'

type Line = {
  background?: boolean
  padding: number
  value: string
}

type EditorProps = BoxProps & {
  branch: string
  filename: string
  isLast: boolean
  lines: Line[]
  prefix: string
  setStatus?: (value: boolean) => void
  status?: boolean
}

export const CodeEditor = (props: EditorProps) => {
  const instances: TypeIt[] = []

  const nextInstance = (index: number) => {
    if (index == instances.length - 1 && props.isLast) {
      props.setStatus(false)
      return
    }
    const instance = instances[index + 1]
    instance.go()
  }

  React.useEffect(() => {
    if (props.status) {
      props.lines.forEach((_, idx) => {
        const id = `${props.prefix}-${idx}`
        const element = document.getElementById(id) as HTMLElement
        element.innerHTML = ' '
        delete element.dataset.typeitId
      })
      props.lines.forEach((line, idx) => {
        const id = `#${props.prefix}-${idx}`
        const instance: TypeIt = new TypeIt(id, {
          afterComplete: async (instance: TypeIt) => {
            instance.destroy()
            nextInstance(idx)
          },
          speed: 30,
          strings: line.value,
        })

        instances.push(instance)
      })
      instances[0].go()
    }
  }, [props.status])

  return (
    <Box id={props.id} boxShadow="sm" minW="50%" rounded="lg">
      <HStack background="gray.400" height={8} px={2}>
        <Icon color="white" as={FaPython} />
        <Text color="white" fontSize="xs" py={2}>
          {props.filename}
        </Text>
      </HStack>
      <pre
        className="language-python"
        style={{ background: '#fff', margin: 0 }}
      >
        {props.lines.map((line, idx) => {
          const backgroundColor =
            line && line.background == true ? '#D9E6D9' : '#fff'
          const language =
            line && line.background ? 'language-none' : 'language-python'
          return (
            <code
              className={language}
              id={`${props.prefix}-${idx}`}
              style={{
                background: backgroundColor,
                display: 'block',
                padding: `0 0 0 ${line.padding}px`,
              }}
            >
              {line.value}
            </code>
          )
        })}
      </pre>
      <HStack background="gray.700" height={8} px={2}>
        <Icon color="white" as={FaCodeBranch} />
        <Text color="white" fontSize="xs" py={2}>
          {props.branch}
        </Text>
      </HStack>
    </Box>
  )
}
