import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  LightMode,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-location'

import { PosterProps } from '../../app/@types/poster'

export const Hero = (props: PosterProps) => {
  const { t } = useTranslation()
  return (
    <Box
      as="section"
      py="12"
      position="relative"
      h={{ base: '560px', md: '640px' }}
      bgColor="#002349"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center
          flexDirection="column"
          textAlign="center"
          color="white"
          h="full"
        >
          <Heading as="h1" size="3xl" fontWeight="extrabold">
            {t(`posters.${props.viewModel.name}.hero.heading`)}
          </Heading>
          <Text fontSize="xl" mt="4" maxW="xl" mx="auto">
            {t(`posters.${props.viewModel.name}.hero.subheading`)}
          </Text>
          <LightMode>
            <Button
              colorScheme="blue"
              size="lg"
              mt="6"
              fontWeight="bold"
              fontSize="md"
              as={Link}
              to="auth/signup"
            >
              {t(`posters.${props.viewModel.name}.hero.cta`)}
            </Button>
          </LightMode>
        </Center>
      </Box>
      <Box
        width="full"
        height={{ base: 'auto', md: 'lg' }}
        className="group"
        position="relative"
        rounded="lg"
        overflow="hidden"
        boxShadow="lg"
      >
        <Image
          position="absolute"
          top="10%"
          left="10%"
          width="full"
          height={{ base: 'auto', md: 'lg' }}
          objectFit="cover"
          src="http://localhost:8000/images/ide.gif"
        />
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.400"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
          <SimpleGrid columns={{ base: 1, md: 3 }}>
            <Box textAlign="center" color="white">
              <Text>Instances</Text>
              <Text fontSize="3xl">120k</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Snapshots</Text>
              <Text fontSize="3xl">380k+/6.4TB+</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Cost Savings</Text>
              <Text fontSize="3xl">$160k/30% avg.</Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
