import * as Auth from 'aws-amplify/auth'
import { v4 as uuidv4 } from 'uuid'

import { usePassword } from './../../hooks/usePassword'

export type IAuthService = {
  confirmSignIn: (
    user: unknown,
    code: string
  ) => Promise<Auth.ConfirmSignInOutput>
  currentSession: () => Promise<Auth.AuthSession>
  signIn: (userName: string) => Promise<Auth.SignInOutput>
  signOut: () => Promise<void>
  signUp: (email: string, options: object) => Promise<Auth.SignUpOutput>
  signUpBitbucket: () => Promise<unknown>
  signUpGitHub: () => Promise<unknown>
  signUpGoogle: () => Promise<unknown>
}

class AuthServiceClass implements IAuthService {
  confirmSignIn(
    user: unknown,
    code: string
  ): Promise<Auth.ConfirmSignInOutput> {
    return Auth.confirmSignIn({
      challengeResponse: code,
      options: {
        userAttributes: {
          preferred_username: user as string,
        },
      },
    })
  }

  currentSession(): Promise<Auth.AuthSession> {
    return Auth.fetchAuthSession()
  }

  signIn(email: string): Promise<Auth.SignInOutput> {
    return Auth.signIn({
      username: email,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      },
    })
  }

  async signOut(): Promise<void> {
    return await Auth.signOut()
  }

  signUp(email: string, options: object): Promise<Auth.SignUpOutput> {
    const { generate } = usePassword()
    const password = generate(8)

    return Auth.signUp({
      username: uuidv4(),
      password: password,
      options: {
        userAttributes: {
          email,
        },
        ...options,
      },
    })
  }

  async signUpBitbucket(): Promise<unknown> {
    return await Auth.signInWithRedirect({
      provider: { custom: 'Bitbucket' },
    })
  }

  async signUpGitHub(): Promise<unknown> {
    return await Auth.signInWithRedirect({
      provider: { custom: 'GitHub' },
    })
  }

  async signUpGitLab(): Promise<unknown> {
    return await Auth.signInWithRedirect({
      provider: { custom: 'GitLab' },
    })
  }

  async signUpGoogle(): Promise<unknown> {
    return await Auth.signInWithRedirect({
      provider: 'Google',
    })
  }
}

export const AuthService = new AuthServiceClass()
