import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { Countdown } from './Countdown'
import './localization'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('countdown')!
const root = createRoot(container)
root.render(<Countdown />)
