import { TFunction } from 'i18next'
import _ from 'lodash'
import * as React from 'react'
import {
  FiBox,
  FiCpu, // FiEdit2,
  // FiRepeat,
  FiShare,
  FiTrash2,
} from 'react-icons/fi'
// import { IoMdCopy } from 'react-icons/io'
import { VscBrowser } from 'react-icons/vsc'

import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import { GetAccountMembersResponse, PlanType } from '../@types/account'
import { ApplicationError, ApplicationNotice } from '../@types/application'
import {
  InstanceType,
  NewSpace,
  NewSpaceFormInput,
  OperatingSystem,
  ShareSpaceFormInput,
  ShareSpaceResponse,
  Space,
  SpaceStatus,
  SpaceType,
  SpaceUser,
} from '../@types/space'
import { AccountStore } from '../stores/accountStore'
import { ApplicationStore } from '../stores/applicationStore'
import { SpaceStore } from '../stores/spaceStore'

export class SpaceViewModel {
  accountStore: AccountStore
  applicationStore: ApplicationStore
  spaceStore: SpaceStore

  _error: ApplicationError | undefined
  _isLoading = false
  _isProgressing = false
  _notice: ApplicationNotice | undefined
  _onCloseAction: () => void = () => {
    return null
  }
  _selectedSpace?: Space
  _spaceUsers: SpaceUser[] = []
  _users: string[] = []

  constructor(
    accountStore: AccountStore,
    applicationStore: ApplicationStore,
    spaceStore: SpaceStore
  ) {
    this.accountStore = accountStore
    this.applicationStore = applicationStore
    this.spaceStore = spaceStore
  }

  get activeSpaces(): Space[] {
    return _.filter(this.spaceStore.spaces, space => {
      return [SpaceStatus.RUNNING, SpaceStatus.STOPPED].includes(
        space.status as SpaceStatus
      )
    })
  }

  public get error(): ApplicationError | undefined {
    return this._error
  }

  public set error(value: ApplicationError | undefined) {
    this._error = value
  }

  get isLoading(): boolean {
    return this._isLoading
  }

  set isLoading(value: boolean) {
    this._isLoading = value
  }

  get isProgressing() {
    return this._isProgressing
  }

  set isProgressing(value: boolean) {
    this._isProgressing = value
  }

  get notice(): ApplicationNotice | undefined {
    return this._notice
  }

  set notice(value: ApplicationNotice | undefined) {
    this._notice = value
  }

  get onCloseAction(): () => void | undefined {
    return this._onCloseAction
  }

  set onCloseAction(value: () => void | undefined) {
    this._onCloseAction = value
  }

  get selectedSpace(): Space | undefined {
    return this._selectedSpace
  }

  set selectedSpace(spaceId: string | Space | undefined) {
    this.spaceStore.spaces.forEach(space => {
      if (space._id == spaceId) {
        this._selectedSpace = space

        this.selectedSpace?.users.forEach(user => {
          this.users.push(user.id)
        })
      }
    })
  }

  get spaces(): Space[] {
    return this.spaceStore.spaces
  }

  getSpacesForPlan(plan: PlanType): number {
    let numberOfSpaces = 4
    if (plan == PlanType.ORGANIZATION) {
      numberOfSpaces = 64
    }

    return numberOfSpaces
  }

  getSpaceState(spaceId: string) {
    this.spaceStore.spaces.forEach(space => {
      if (space._id == spaceId) {
        return space.status
      }
    })
  }

  set spaceState(value: { spaceId: string; status?: SpaceStatus }) {
    this.spaceStore.spaces.forEach(space => {
      if (space._id == value.spaceId) {
        space.status = value.status
      }
    })
  }

  get spaceUsers(): SpaceUser[] {
    return this._spaceUsers
  }

  set spaceUsers(value: SpaceUser[]) {
    this._spaceUsers = value
  }

  public get users(): string[] {
    return this._users
  }

  // Accounts

  async getAccountMembers() {
    this.isLoading = true
    return await this.accountStore
      .getAccountMembers()
      .then((data: unknown) => {
        this.spaceUsers = (data as GetAccountMembersResponse).users
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  // Spaces

  async createSpace(values: NewSpaceFormInput) {
    this.isLoading = true

    const space: NewSpace = {
      action: 'create',
      configuration: {
        operatingSystem: OperatingSystem.AMZN_LINUX_2023,
        resourceType: InstanceType.T3_MEDIUM,
      },
      name: values.name,
      type: values.type,
    }

    return await this.spaceStore
      .createSpace(space)
      .then(() => {
        this.isProgressing = true
        this.getSpaces(true)
      })
      .catch((error: ApplicationError) => {
        this.isLoading = false
        this.error = error
      })
  }

  async deleteSpace(id: string) {
    return await this.spaceStore
      .deleteSpace(id)
      .then(() => {
        this.getSpaces(true)
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async getSpaces(isProgressing = false) {
    if (isProgressing) {
      this.isProgressing = isProgressing
    } else {
      this.isLoading = true
      this.isProgressing = false
    }
    await this.spaceStore
      .getSpaces()
      .then(() => {
        this.isLoading = false
      })
      .catch((error: ApplicationError) => {
        this.error = error
      })
  }

  async shareSpace(
    spaceId: string,
    values: ShareSpaceFormInput
  ): Promise<void> {
    this.spaceState = { spaceId, status: SpaceStatus.DEPLOYING } // Trigger progress indicator

    if (
      [SpaceType.DEV_CONTAINER, SpaceType.AWS_EC2_INSTANCE].includes(
        this.selectedSpace?.spaceType as SpaceType
      )
    ) {
      this.spaceStore
        .shareSpace(spaceId, values.members)
        .then((data: unknown) => {
          if (values.members.length) {
            this.notice = {
              message: 'requests.instances.share.notice.message',
            }
          } else {
            this.notice = {
              message: 'requests.instances.unshare.notice.message',
            }
          }

          this.spaces.forEach((space: Space) => {
            if (space._id == spaceId) {
              space.users = (data as ShareSpaceResponse).users
            }
          })

          this.getSpaces(true)
        })
        .catch((error: ApplicationError) => {
          this.error = error
        })
    } else {
      this.spaceStore
        .shareSpace(spaceId, values.members)
        .then((data: unknown) => {
          if (values.members.length) {
            this.notice = {
              message: 'requests.instances.share.notice.message',
            }
          } else {
            this.notice = {
              message: 'requests.instances.unshare.notice.message',
            }
          }

          this.spaces.forEach((space: Space) => {
            if (space._id == spaceId) {
              space.users = (data as ShareSpaceResponse).users
            }
          })

          this.getSpaces(true)
        })
        .catch((error: ApplicationError) => {
          this.error = error
        })
    }
  }

  watchInstances() {
    if (
      _.some(this.spaceStore.spaces, ['status', 'deleting']) ||
      _.some(this.spaceStore.spaces, ['status', 'deploying'])
    ) {
      const id = setInterval(() => {
        this.getSpaces(true)
      }, 15000)

      return () => clearInterval(id)
    }
  }

  // UI

  isActive(space: Space): boolean {
    return ['paused', 'running', 'stopped'].includes(space.status as string)
  }

  tableColumns(
    t: TFunction<'translation', undefined>,
    // onCopyInstanceDetails: (
    //   event: unknown,
    //   instanceDetails: {
    //     ipAddress?: string
    //     port?: string
    //     spaceId?: string
    //     spaceType?: string
    //   }
    // ) => void,
    // onRestoreInstance: (event: unknown, spaceId: string) => void,
    onShareSpace: (event: unknown, spaceId: string) => void,
    // onShowSpace: (event: unknown, spaceId: string) => void,
    onDeleteSpace: (event: unknown, spaceId: string) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): ColumnDef<Space, string | any>[] {
    const willShareSpaces =
      this.applicationStore.featureFlagFor('willShareSpaces')

    const columnHelper = createColumnHelper<Space>()
    return [
      columnHelper.accessor('spaceType', {
        header: () => '',
        cell: props => (
          <Icon
            as={
              props.row.original.spaceType == 'devcontainer'
                ? FiBox
                : props.row.original.spaceType == 'ec2'
                ? FiCpu
                : VscBrowser
            }
          />
        ),
        size: 16,
      }),
      columnHelper.accessor('name', {
        header: () => 'Name',
        cell: props => (
          <Stack>
            <Text fontWeight="medium">{props.row.original.name}</Text>
            {!['deleted', 'deploying', 'pending_usage'].includes(
              props.row.original.status as string
            ) ? (
              <VStack align="left" spacing={1}>
                {/* <HStack>
                  <Text color="muted" fontSize="xs">
                    {t('instances.index.table.instance.cli')}
                  </Text>
                  <IconButton
                    aria-label="Connect using CLI"
                    as={IoMdCopy}
                    onClick={event =>
                      onCopySpaceDetails(event, {
                        spaceId: props.row.original._id,
                        spaceType: props.row.original.spaceType,
                      })
                    }
                    minWidth={3}
                    width={3}
                    height={4}
                    background="none"
                    cursor="pointer"
                  />
                </HStack>
                {this.hasAddress(props.row.original) &&
                  props.row.original.spaceType != 'devcontainer' && (
                    <HStack>
                      <Text color="muted" fontSize="xs">
                        {t('instances.index.table.instance.browser')}
                      </Text>
                      <IconButton
                        aria-label="Connect using Browser"
                        as={IoMdCopy}
                        onClick={event =>
                          onCopyInstanceDetails(event, {
                            ipAddress: props.row.original.ipAddress,
                            port: props.row.original.port,
                            spaceType: props.row.original.spaceType,
                          })
                        }
                        minWidth={3}
                        width={3}
                        height={4}
                        background="none"
                        cursor="pointer"
                      />
                    </HStack>
                  )} */}
              </VStack>
            ) : (
              <></>
            )}
          </Stack>
        ),
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: props =>
          ['deploying', 'deleting'].includes(
            props.row.original.status as string
          ) ? (
            <>
              <Badge size="sm" colorScheme="orange">
                {t(`spaces.index.table.status.${props.row.original.status}`)}
              </Badge>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray" ml={2}>
                  {t(`spaces.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ) : ['deleted'].includes(props.row.original.status as string) ? (
            <>
              <Badge size="sm" colorScheme="gray">
                {t(`spaces.index.table.status.${props.row.original.status}`)}
              </Badge>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray" ml={2}>
                  {t(`spaces.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ) : !['deleted'].includes(props.row.original.status as string) ? (
            <>
              <Badge
                size="sm"
                colorScheme={
                  props.row.original.status === 'running'
                    ? 'green'
                    : ['paused', 'stopped'].includes(
                        props.row.original.status as string
                      )
                    ? 'blue'
                    : 'red'
                }
              >
                {t(`spaces.index.table.status.${props.row.original.status}`)}
              </Badge>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray" ml={2}>
                  {t(`spaces.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ) : (
            <>
              {((props.row.original.users && props.row.original.users.length) ||
                props.row.original.isShared) && (
                <Badge size="sm" colorScheme="gray">
                  {t(`spaces.index.table.status.shared`)}
                </Badge>
              )}
            </>
          ),
      }),
      columnHelper.accessor('status', {
        id: 'status2',
        header: () => '',
        cell: props =>
          !['deploying', 'deleting'].includes(
            props.row.original.status as string
          ) ? (
            <HStack justify="flex-end" spacing="1">
              {willShareSpaces && (
                <Tooltip label={t('spaces.index.table.buttons.share.label')}>
                  <IconButton
                    id="pw-share-button"
                    icon={<FiShare fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Share instance"
                    onClick={event =>
                      onShareSpace(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              )}
              <Tooltip label={t('spaces.index.table.buttons.delete.label')}>
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete space"
                  onClick={event =>
                    onDeleteSpace(event, props.row.original._id)
                  }
                />
              </Tooltip>

              {!props.row.original.isShared &&
              ['deploying', 'deleting'].includes(
                props.row.original.status as string
              ) ? (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              ) : (
                <></>
              )}
            </HStack>
          ) : !props.row.original.isShared &&
            this.isActive(props.row.original) ? (
            <HStack justify="flex-end" spacing="1">
              {/* <Tooltip label={t('spaces.index.table.buttons.edit.label')}>
                <IconButton
                  id="pw-edit-button"
                  icon={<FiEdit2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Edit instance"
                  onClick={event => onShowSpace(event, props.row.original._id)}
                />
              </Tooltip> */}
              {willShareSpaces && (
                <Tooltip label={t('spaces.index.table.buttons.share.label')}>
                  <IconButton
                    id="pw-share-button"
                    icon={<FiShare fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Share instance"
                    onClick={event =>
                      onShareSpace(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              )}
              {/* {this.hasSnapshot(props.row.original) ? (
                <Tooltip
                  label={t('instances.index.table.buttons.restore.label')}
                >
                  <IconButton
                    icon={<FiRepeat fontSize="1.25rem" />}
                    variant="ghost"
                    aria-label="Restore instance"
                    onClick={event =>
                      onRestoreInstance(event, props.row.original._id)
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  label={t(
                    'instances.index.table.buttons.restore.pending.label'
                  )}
                >
                  <IconButton
                    aria-label="Restore instance"
                    bgColor="white"
                    color="gray.500"
                    cursor="not-allowed"
                    disabled={true}
                    icon={<FiRepeat fontSize="1.25rem" />}
                    _hover={{
                      background: 'none',
                    }}
                  />
                </Tooltip>
              )} */}
              <Tooltip label={t('spaces.index.table.buttons.delete.label')}>
                <IconButton
                  icon={<FiTrash2 fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Delete space"
                  onClick={event =>
                    onDeleteSpace(event, props.row.original._id)
                  }
                />
              </Tooltip>
            </HStack>
          ) : !props.row.original.isShared &&
            ['deploying', 'deleting'].includes(
              props.row.original.status as string
            ) ? (
            <HStack justify="flex-end" spacing="1">
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="md"
              />
            </HStack>
          ) : !props.row.original.isShared &&
            ['pending_usage', 'deleted'].includes(
              props.row.original.status as string
            ) ? (
            <HStack justify="flex-end" spacing="1">
              {/* <Tooltip label={t('spaces.index.table.buttons.restore.label')}>
                <IconButton
                  icon={<FiRepeat fontSize="1.25rem" />}
                  variant="ghost"
                  aria-label="Restore instance"
                  onClick={event =>
                    onRestoreInstance(event, props.row.original._id)
                  }
                />
              </Tooltip> */}
            </HStack>
          ) : (
            <></>
          ),
        enableSorting: false,
      }),
    ]
  }
}
